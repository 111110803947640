<template>
  <div class="contactus">
    <div class="container py-5 py-lg-6">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-6">
          <h5 class="fw-bold pb-3 pb-lg-4">聯絡我們</h5>
          <p>親愛的會員您好！</p>
          <p>遇到問題了嗎？需要我們的服務，可撥打 0809-080-038 客服專線。</p>
          <p>我們將儘速為您服務。</p>
          <p>或填寫下方表單；請先選擇問題類型，並於【意見欄】概述問題內容或建議，我們將儘速回覆您。</p>
          <p>您可在下方查閱相關回覆，謝謝！</p>
          <p class="mb-4">※ 客服服務時間:週一至週五 09:00-12:00, 13:30-18:00 (例假日/國定假日休息)</p>
          <!-- 問題類別 -->
          <Form v-slot="{ errors }" ref="disabledd" class="mb-4 mb-lg-5">
            <div class="card rounded-0">
              <div class="card-header p-0">
                <h5 class="bg-secondary text-white px-2 px-sm-4 px-lg-7 py-3">問題類別</h5>
              </div>
              <div class="card-body px-2 px-sm-4 px-lg-7 pt-0 pb-4 pb-lg-7">
                <table class="table mb-4">
                  <tr class="border-bottom border-dark">
                    <td class="w-sm-25 w-md-20 text-nowrap py-3">
                      <div class="form-check mb-0 ps-0">
                        <input class="form-check-input" type="radio" name="問題類別" id="orderQuestion" v-model="checkInfoQuestion" value="訂單問題">
                        <label class="form-check-label" for="orderQuestion">
                          訂單問題
                        </label>
                      </div>
                    </td>
                    <td>
                      <Field type="text" class="form-control" name="訂單編號" :rules="isOrder" id="orderNumber" :class="{ 'is-invalid': errors['訂單編號'] }"
                        v-model="orderNo" placeholder="訂單編號"></Field>
                      <ErrorMessage name="訂單編號" class="invalid-feedback p-0"></ErrorMessage>
                    </td>
                  </tr>
                  <tr class="border-bottom border-dark">
                    <td class="text-nowrap py-3">
                      <div class="form-check mb-0 ps-0">
                        <input class="form-check-input" type="radio" name="問題類別" id="productQuestion" v-model="checkInfoQuestion" value="商品問題">
                        <label class="form-check-label" for="productQuestion">
                          商品問題
                        </label>
                      </div>
                    </td>
                    <td>
                      <Field type="text" class="form-control" name="商品編號" :rules="isProduct" id="orderNumber" :class="{ 'is-invalid': errors['商品編號'] }"
                      v-model="productNo" placeholder="商品名稱"></Field>
                      <ErrorMessage name="商品編號" class="invalid-feedback p-0"></ErrorMessage>
                    </td>
                  </tr>
                  <tr class="border-bottom border-dark">
                    <td class="text-nowrap py-3">
                      <div class="form-check mb-0 ps-0">
                        <input class="form-check-input" type="radio" name="問題類別" id="eventQuestion" v-model="checkInfoQuestion" value="活動問題">
                        <label class="form-check-label" for="eventQuestion">
                          活動問題
                        </label>
                      </div>
                    </td>
                    <td>優惠券、點數相關</td>
                  </tr>
                  <tr class="border-bottom border-dark">
                    <td class="text-nowrap py-3">
                      <div class="form-check mb-0 ps-0">
                        <input class="form-check-input" type="radio" name="問題類別" id="cooperationQuestion" v-model="checkInfoQuestion" value="異業合作">
                        <label class="form-check-label" for="cooperationQuestion">
                          異業合作
                        </label>
                      </div>
                    </td>
                    <td>行政配合事項相關</td>
                  </tr>
                  <tr class="border-bottom border-dark">
                    <td class="text-nowrap py-3">
                      <div class="form-check mb-0 ps-0">
                        <input class="form-check-input" type="radio" name="問題類別" id="otherQuestion" v-model="checkInfoQuestion" value="其他問題">
                        <label class="form-check-label" for="otherQuestion">
                          其他問題
                        </label>
                      </div>
                    </td>
                    <td>非上述四項問題類型</td>
                  </tr>
                  <tr>
                    <td class="text-nowrap align-top py-3">
                      <label class="form-check-label py-0 px-4" for="comment">
                        意見欄
                      </label>
                    </td>
                    <td class="py-3">
                      <textarea
                        rows="5"
                        style="resize: none; width: 100%;"
                        name="意見欄"
                        id="comment"
                        v-model="comment"
                        class="rounded-3"
                        placeholder="字數最多 50 字"
                      />
                    </td>
                  </tr>
                </table>
                <div class="d-grid gap-2">
                  <button class="btn btn-primary py-2" type="button"  :class="sendBtnUnclickable?'disabled' : '' "   @click.prevent="questionConfirm">確認送出</button>
                </div>
              </div>
            </div>
          </Form>
          <!-- 客服紀錄 -->
          <div class="card">
            <div class="card-header p-0">
              <h5 class="bg-secondary text-white px-2 px-sm-4 px-lg-7 py-3">客服紀錄</h5>
            </div>
            <div class="card-body px-2 px-sm-4 px-lg-7">
              <div v-if="records.length === 0" class="fs-5">目前無紀錄</div>
              <table v-else class="table border" v-for="record in records" :key="record">
                <tbody>
                  <tr>
                    <th class="text-primary pe-0" width="25%">問題編號:</th>
                    <th>{{ record.serialNumber }}</th>
                  </tr>
                  <tr>
                    <td class="pe-0">提問日期:</td>
                    <td>{{ record.askDate }}</td>
                  </tr>
                  <tr>
                    <td class="pe-0">問題分類:</td>
                    <td class="text-nowrap">{{ record.questionType }}</td>
                  </tr>
                  <tr>
                    <td class="pe-0">問題內容:</td>
                    <td v-html="record.question"></td>
                  </tr>
                  <tr class="bg-light">
                    <td class="pe-0">回覆內容:</td>
                    <td v-html="record.responseQuestion"></td>
                  </tr>
                  <tr class="bg-light">
                    <td class="pe-0">回覆時間:</td>
                    <td>{{ record.replierDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sendBtnUnclickable: false,
      orderNo: '',
      productNo: '',
      comment: '',
      checkInfoQuestion: '',
      submitQuestion: {
        QuestionType: '', //* 選取的類別(radio)
        Question: '' //* 訂單編號、商品編號+意見欄
      },
      records: []
    }
  },
  methods: {
    isOrder (value) {
      if (this.checkInfoQuestion === '訂單問題') {
        return value ? true : '請輸入訂單編號'
      } else {
        return true
      }
    },
    isProduct (value) {
      if (this.checkInfoQuestion === '商品問題') {
        return value ? true : '請輸入商品名稱'
      } else {
        return true
      }
    },
    // *會員提問
    questionConfirm () {
      // *問題類別-訂單問題(訂單編號必填)
      if (this.checkInfoQuestion === '訂單問題' && !this.orderNo) {
        this.$swal.fire({
          title: '請填寫訂單編號',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }
      // *問題類別-商品問題(商品名稱必填)
      if (this.checkInfoQuestion === '商品問題' && !this.productNo) {
        this.$swal.fire({
          title: '請填寫商品名稱',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }
      // *意見欄為必填
      if (this.comment === '') {
        this.$swal.fire({
          title: '意見欄為必填',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }

      this.sendBtnUnclickable = true

      this.submitQuestion.QuestionType = this.checkInfoQuestion
      this.submitQuestion.Question = this.orderNo || this.productNo ? `${this.orderNo}${this.productNo}<br>意見欄:${this.comment}` : `意見欄:${this.comment}`

      const url = `${process.env.VUE_APP_API}/api/members/OnlineAskQuestion`
      this.$http.post(url, this.submitQuestion)
        .then((res) => {
          if (res.data.rtnCode !== 0) {
            this.sendBtnUnclickable = false
            this.$swal.fire({
              title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
              allowOutsideClick: true,
              confirmButtonColor: '#003894',
              confirmButtonText: '確認',
              width: 400,
              customClass: {
                title: 'text-class',
                confirmButton: 'confirm-btn-class'
              }
            })
            return ''
          }
          this.$swal.fire({
            title: '提問已傳送',
            confirmButtonColor: '#003894',
            showConfirmButton: true,
            timer: 1000,
            width: 400,
            customClass: {
              title: 'text-class',
              confirmButton: 'confirm-btn-class'
            }
          })
          this.orderNo = ''
          this.productNo = ''
          this.comment = ''
          this.checkInfoQuestion = ''
          this.getQuestions()
          this.sendBtnUnclickable = false
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.questionConfirm()
            this.sendBtnUnclickable = false
          }
        })
    },
    // *會員提問紀錄
    getQuestions () {
      const url = `${process.env.VUE_APP_API}/api/members/QuestionSelects`
      this.$http.get(url)
        .then((res) => {
          // if (res.data.rtnCode !== 0) {
          //   this.$swal.fire({
          //     title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
          //     allowOutsideClick: true,
          //     confirmButtonColor: '#003894',
          //     confirmButtonText: '確認',
          //     width: 400,
          //     customClass: {
          //       title: 'text-class',
          //       confirmButton: 'confirm-btn-class'
          //     }
          //   })
          //   return ''
          // }
          if (res.data.rtnCode === 0) {
            this.records = res.data.info
            this.records.forEach((item) => {
              if (item.question) {
                item.question = item.question.replace('\n', '<br>')
              }
              if (item.responseQuestion) {
                // item.responseQuestion = item.responseQuestion.split('\\n\\r').join('<br>')
                item.responseQuestion = item.responseQuestion.split('\\n').join('<br>')
                // item.responseQuestion = item.responseQuestion.split('\\r').join('<br>')
              }
            })
            //*  提問記錄排序
            this.records.sort((a, b) => {
              return parseInt(b.serialNumber) - parseInt(a.serialNumber)
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.getQuestions()
          }
        })
    }
  },
  watch: {
    checkInfoQuestion () {
      this.orderNo = ''
      this.productNo = ''
      this.$refs.disabledd.setErrors({
        orderNo: '',
        productNo: ''
      })
    }
  },
  mounted () {
    this.getQuestions()
  }
}
</script>

<style lang="scss" scoped>
.contactus{
  background-color: #f0f0f2;
}

.form-check .form-check-input{
  margin-left: 0;
  margin-right: 4px;
  @media (min-width: 576px) {
    margin-right: 8px;
  }
}

.table > :not(:last-child) > :last-child > *{
  border-color: rgb(118, 118, 118);
}
</style>
